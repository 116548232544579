import React from 'react';
import { BarChart, Bar, Tooltip, Legend, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const BAR_SIZE = 60;

function StackedBarChart(props) {
    return (
        <>
            <ResponsiveContainer width="100%" height={props.height}>
                <BarChart
                    data={props.data}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis tickLine={false} dataKey="name" />
                    <YAxis tickLine={false} type="number" domain={[0, 100]} />
                    <Tooltip />
                    <Legend
                        verticalAlign="top"
                        iconType='square'
                        height="60px"
                    />
                    {Object.keys(props.colorMap).map((color) => (
                        <Bar barSize={BAR_SIZE} dataKey={color} stackId="a" fill={props.colorMap[color]} />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </>

    );
}

export default StackedBarChart;