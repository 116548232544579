import { Box, Grid, Typography, TextField, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextSwitch from '../../components/TextSwitch';
import dayjs from 'dayjs';
import moment from 'moment';
import VerticalBarChart from '../../components/charts/VerticalBarChart';
import StackedBarChart from '../../components/charts/StackedBarChart';
import DownloadReportsDialog from './DownloadReportsDialog';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMonitorData, getMonitorDataSelector, getMonitorStatusSelector } from '../../redux/slices/monitorSlice';

const initalBreakupState = [
    {
        "label": "Mass",
        "selected": true
    },
    {
        "label": "Items",
        "selected": false
    },
    {
        "label": "Value",
        "selected": false
    }
]


const initalTimePeriodState = [
    {
        "label": "Live",
        "selected": false
    },
    {
        "label": "Past",
        "selected": true
    }
]

const initalTimeState = [
    {
        label: "1m",
        time: 1,
        unit: "minute",
        selected: true
    },
    {
        label: "30m",
        time: 30,
        unit: "minute",
        selected: false
    },
    {
        label: "1H",
        time: 1,
        unit: "hour",
        selected: false
    }
]

const typeMap = {
    Past: "PAST",
    Live: "LIVE"
}

const breakdownMap = {
    Mass: "MASS",
    Items: "ITEMS",
    Value: "VALUE",
}

const getSelectedSwitchIndex = (obj) => {
    for (let i = 0; i < obj.length; i++) {
        const o = obj[i]
        if (o.selected) {
            return i
        }
    }
    return -1
}

const pendingStateView = () => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: "100%",
                paddingTop: 5,
                minHeight: "90vh"
            }}
        >
            <Grid item>
                <CircularProgress />
            </Grid>
        </Grid>
    )
}

function Monitor(props) {
    const dispatch = useDispatch()
    const [breakupState, setBreakupState] = useState(initalBreakupState);
    const [timePeriodState, setTimePeriodState] = useState(initalTimePeriodState);
    const [timeState, setTimeState] = useState(initalTimeState);
    let date = moment().startOf('day')
    const format = 'YYYY-MM-DDTHH:mm:ss.SSS'
    const [toDate, setToDate] = useState(dayjs(date.format(format)));
    const [fromDate, setFromDate] = useState(dayjs(date.subtract(6, 'day').format(format)));

    const status = useSelector(getMonitorStatusSelector)
    const monitorData = useSelector(getMonitorDataSelector)

    useEffect(() => {
        if (status === 'idle') {
            const body = {
                type: typeMap[`${timePeriodState[getSelectedSwitchIndex(timePeriodState)].label}`],
                breakdown: breakdownMap[`${breakupState[getSelectedSwitchIndex(breakupState)].label}`],
                start_date: fromDate.format(format),
                end_date: toDate.format(format),
                cust_id: 1
            }
            dispatch(fetchMonitorData(body))
        }
    }, [status, dispatch])

    useEffect(() => {
        if (status !== 'idle') {
            let tmp = typeMap[`${timePeriodState[getSelectedSwitchIndex(timePeriodState)].label}`]
            let body = {
                type: tmp,
                breakdown: breakdownMap[`${breakupState[getSelectedSwitchIndex(breakupState)].label}`],
                cust_id: 1
            }
            if (tmp === "LIVE") {
                let t = timeState[getSelectedSwitchIndex(timeState)]
                body.time_gap = t.time
                body.time_unit = t.unit
            } else {
                body.start_date = fromDate.format(format)
                body.end_date = toDate.format(format)
            }
            dispatch(fetchMonitorData(body))
        }
    }, [breakupState, timePeriodState, timeState, toDate, fromDate])

    const timeType = typeMap[`${timePeriodState[getSelectedSwitchIndex(timePeriodState)].label}`]

    const validateDateChange = (date, type) => {
        if (type === 'fromDate') {
            return (toDate.diff(date, 'day') > 0 && toDate.diff(date, 'day') < 7)
        } else {
            return (date.diff(fromDate, 'day') > 0 && date.diff(fromDate, 'day') < 7)
        }
    }

    return (
        <Box sx={{
            width: "100%",
            paddingTop: 5
        }}>
            <Grid container spacing={5} sx={{ mb: 5 }}>
                <Grid item xs={12} md="auto">
                    <Typography variant="h6" component="div" sx={{ mb: "10px", fontWeight: 400 }}>
                        Select the breakup parameters
                    </Typography>
                    <TextSwitch value={breakupState} handleSwitch={(value) => setBreakupState(value.value)} />
                </Grid>
                <Grid item xs={12} md="auto">
                    <Typography variant="h6" component="div" sx={{ mb: "10px", fontWeight: 400 }}>
                        Select type of time period
                    </Typography>
                    <TextSwitch value={timePeriodState} handleSwitch={(value) => setTimePeriodState(value.value)} />
                </Grid>
                {timeType === "PAST" && (<Grid container item xs={12} md="auto" spacing={5}>
                    <Grid item xs={6}>
                        <Typography variant="h6" component="div" sx={{ mb: "10px", fontWeight: 400 }}>
                            Select from date
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="DD/MM/YYYY"
                                value={fromDate}
                                onChange={(newValue) => {
                                    if (validateDateChange(newValue, 'fromDate')) {
                                        setFromDate(newValue)
                                    } else {
                                        if (toDate.diff(newValue, 'day') > 6) {
                                            let adjust = toDate.diff(newValue, 'day') - 6
                                            // adjusting the to date for ${adjust} days backward
                                            setToDate(toDate.subtract(adjust, 'day'));
                                            setFromDate(newValue);
                                        }
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} variant="standard" />}
                                maxDate={dayjs()}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" component="div" sx={{ mb: "10px", fontWeight: 400 }}>
                            Select to date
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="DD/MM/YYYY"
                                value={toDate}
                                onChange={(newValue) => {
                                    if (validateDateChange(newValue, 'toDate')) {
                                        setToDate(newValue)
                                    } else {
                                        if (newValue.diff(fromDate, 'day') > 6) {
                                            let adjust = newValue.diff(fromDate, 'day') - 6
                                            // adjusting the from date for ${adjust} days forward
                                            setFromDate(fromDate.add(adjust, 'day'));
                                            setToDate(newValue);
                                        }
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} variant="standard" />}
                                shouldDisableDate={(date) => (date.diff(fromDate, 'day') < 0)}
                                maxDate={dayjs()}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>)}
                {timeType === "LIVE" && (
                    <Grid item xs={12} md="auto">
                        <Typography variant="h6" component="div" sx={{ mb: "10px", fontWeight: 400 }}>
                            Select time period
                        </Typography>
                        <TextSwitch value={timeState} handleSwitch={(value) => setTimeState(value.value)} />
                    </Grid>
                )}
                <Grid item xs={12} md="auto">
                    <Typography variant="h6" component="div" sx={{ mb: "10px", fontWeight: 400 }} >
                        Analytics Reports
                    </Typography>
                    <DownloadReportsDialog />
                </Grid>
            </Grid>

            {status === 'loading' && (pendingStateView())}

            {(status !== 'loading' && monitorData.composition.length > 0) && (
                <Grid container>
                    <Grid container item xs={12} md={5}>
                        <Grid container item xs={12}
                            sx={{ borderRadius: 2, border: 1, padding: "8px", textAlign: "center", borderColor: '#E8E4E4' }}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h6" sx={{ mr: "18px", fontWeight: 400, display: "inline" }}>
                                Total {breakupState[getSelectedSwitchIndex(breakupState)].label} Segregated
                            </Typography>
                            <Typography variant="h2" sx={{ mr: "8px", fontWeight: 700, display: "inline", color: "primary.main", fontFamily: "Poppins" }}>
                                {monitorData.total.value}
                                <Typography variant="h4" component="span" sx={{ fontWeight: 900, ml: "10px", color: "#000", fontFamily: "Poppins" }}>
                                    {monitorData.total.unit}
                                </Typography>
                            </Typography>

                        </Grid>

                        <Grid container item xs={12}
                            sx={{ borderRadius: 2, border: 1, padding: "18px", borderColor: '#E8E4E4', mt: "10px" }}
                        >
                            <Typography variant="h6" sx={{ mr: "18px", fontWeight: 400 }}>
                                Composition Breakup
                            </Typography>

                            <VerticalBarChart height={500} data={monitorData.composition} margin={{
                                left: 20,
                                top: 20
                            }} />

                        </Grid>
                    </Grid>

                    <Grid container item xs={12} md={7}
                        sx={{ paddingLeft: "10px" }}
                    >
                        <Grid container item xs={12}
                            sx={{ borderRadius: 2, border: 1, borderColor: '#E8E4E4', padding: "10px" }}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >

                            <Box sx={{ position: 'relative', width: '100%' }}>
                                <Typography variant="h6" sx={{ fontWeight: 800, textAlign: 'center' }}>
                                    Chronological Composition
                                </Typography>

                                <Typography sx={{ fontWeight: 100, position: 'absolute', bottom: 2.5, left: 40 }}>
                                    Visible Classes {`${monitorData.visible.visible}/${monitorData.visible.total}`}
                                </Typography>
                            </Box>


                            <StackedBarChart data={monitorData.chronology} colorMap={monitorData.colorMap} visibleClasses={monitorData.visible} height={500} />

                        </Grid>
                    </Grid>
                </Grid>
            )}



        </Box >
    );
}

export default Monitor;