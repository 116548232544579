import { configureStore } from '@reduxjs/toolkit'
import monitorReducer from './slices/monitorSlice'

export const store = configureStore({
    reducer: {
        monitor: monitorReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})