import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const MainStyle = styled('Box')(({ theme }) => ({
    display: 'inline-flex'
}));

function TextButton(props) {
    const { text, handleClick, sx } = props;

    return (
        <MainStyle>
            <Box sx={{
                border: 1,
                borderColor: '#E8E4E4',
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                display: 'inline-block',
                padding: '5px 10px',
                fontWeight: 400,
                minWidth: 128,
                textAlign: 'center',
                ':hover': {
                    cursor: 'pointer',
                },
                ':active': {
                    cursor: 'pointer',
                    bgcolor: 'primary.dark',
                },
                ...sx
            }} onClick={() => handleClick()} >
                {text}
            </Box>
        </MainStyle>
    );
}

export default TextButton;