import React, { useState } from 'react';
import { Typography, Box, AppBar, Toolbar } from '@mui/material';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import TextSwitch from '../components/TextSwitch';

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    minHeight: '90vh',
    [theme.breakpoints.up('md')]: {
        paddingLeft: '120px',
        paddingRight: '120px',
    }
}));

const FooterStyle = styled('div')(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
    color: '#BBBBBB',
    padding: "15px",
}));


function DashboardLayout(props) {
    let navigate = useNavigate();
    const location = useLocation();

    const getInitialTabState = () => {
        let initalTabState = [
            {
                "label": "Monitor",
                "selected": (location.pathname === '/')
            },
            {
                "label": "Assess Quality",
                "selected": (location.pathname === '/quality')
            }
        ]
        return initalTabState
    }

    const [tabState, setTabState] = useState(getInitialTabState());


    const handleTabSwitch = (obj) => {
        setTabState(obj.value);
        const selectedLabel = obj.selectedLabel;
        if (selectedLabel === "Monitor") {
            navigate("/");
        } else if (selectedLabel === "Assess Quality") {
            navigate("/quality");
        }
    }

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" elevation={0} color='secondary'>
                    <Toolbar>
                        <Typography variant="h3" sx={{
                            fontFamily: "Poppins",
                            fontWeight: "700",
                            lineHeight: "32px",
                            ml: "15px",
                            '@media (min-width:900px)': {
                                ml: "49px",
                            },
                            mt: "26px",
                            mb: "16px",
                            letterSpacing: "2px"
                        }} >
                            EcoMonitor
                        </Typography>

                        <Typography variant="caption" sx={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            lineHeight: "32px",
                            ml: "5px",
                            mt: "20px",
                            color: "text.secondary"
                        }} >
                            by EcoOrbit
                        </Typography>

                        <Box sx={{
                            marginLeft: "auto",
                            '@media (min-width:700px)': {
                                mr: "49px",
                            },
                        }}>
                            <Typography variant="h6" component="div" sx={{
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                lineHeight: "32px",
                            }}>
                                Material Recovery Facility
                            </Typography>
                            <Typography variant="h6" sx={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                lineHeight: "32px",
                                color: "text.secondary",
                                mt: "-6px"
                            }}>
                                Logout
                            </Typography>
                        </Box>


                    </Toolbar>
                </AppBar>
            </Box>
            <MainStyle>
                <TextSwitch value={tabState} handleSwitch={(value) => handleTabSwitch(value)} />
                <Outlet />
            </MainStyle>
            <FooterStyle>
                Copyrights reserved - EcoOrbit Solutions 2022
            </FooterStyle>
        </div>
    );
}

export default DashboardLayout;