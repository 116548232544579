import React from 'react';
import { Typography } from '@mui/material';

export function Metric(props) {
    return (
        <>
            <Typography variant="h2" sx={{ mr: "8px", fontWeight: 700, display: "inline", color: "primary.main", fontFamily: "Poppins" }}>
                {props.value}
                <Typography variant="h4" component="span" sx={{ fontWeight: 900, ml: "10px", color: "#000", fontFamily: "Poppins" }}>
                    {props.subText}
                </Typography>
            </Typography>
        </>
    );
}