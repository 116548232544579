import { useRoutes } from 'react-router-dom';

import DashboardLayout from './layouts/DashboardLayout';
import AssessQuality from './pages/dashboard/AssessQuality';
import Monitor from './pages/dashboard/Monitor';

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <DashboardLayout />,
            children: [
                { path: '/', element: <Monitor /> },
                { path: '/quality', element: <AssessQuality /> }
            ]
        }
    ])
}