import React, { useState } from 'react';
import { InputLabel, MenuItem, FormControl, Select, Box, Grid, Typography } from '@mui/material';
import MetricBox from '../../components/MetricBox';
import VerticalBarChart from '../../components/charts/VerticalBarChart';
import StackedBarChart from '../../components/charts/StackedBarChart';

const labelToColorMap = {
    petBottles: {
        name: "PET Bottles",
        color: "#772C2A"
    },
    hdpe: {
        name: "HDPE",
        color: "#2C4D75"
    },
    ppe: {
        name: "PPE",
        color: "#F79646"
    },
    paperClips: {
        name: "Paper Clips",
        color: "#4BACC6"
    },
    carboard: {
        name: "Carboard",
        color: "#8064A2"
    },
    coloredPet: {
        name: "Coloured PET",
        color: "#C0504D"
    }
}

const vertialData = [
    {
        name: "T",
        uv: 99,
        percent: "99%",
        color: "#5A9B50"
    },
    {
        name: "T-1",
        uv: 76,
        percent: "76%",
        color: "#5A9B50"
    },
    {
        name: "T-2",
        uv: 85,
        percent: "85%",
        color: "#5A9B50"
    },
    {
        name: "T-3",
        uv: 50,
        percent: "50%",
        color: "#5A9B50"
    },
    {
        name: "T-4",
        uv: 60,
        percent: "60%",
        color: "#5A9B50"
    },
    {
        name: "T-5",
        uv: 80,
        percent: "80%",
        color: "#5A9B50"
    },
    {
        name: "T-6",
        uv: 86,
        percent: "86%",
        color: "#5A9B50"
    },
]

const stackedData = [
    {
        name: "T",
        [labelToColorMap.petBottles.name]: 20,
        [labelToColorMap.hdpe.name]: 10,
        [labelToColorMap.ppe.name]: 10,
        [labelToColorMap.paperClips.name]: 20,
        [labelToColorMap.carboard.name]: 20,
        [labelToColorMap.coloredPet.name]: 20,

    },
    {
        name: "T-1",
        [labelToColorMap.petBottles.name]: 5,
        [labelToColorMap.hdpe.name]: 25,
        [labelToColorMap.ppe.name]: 10,
        [labelToColorMap.paperClips.name]: 10,
        [labelToColorMap.carboard.name]: 20,
        [labelToColorMap.coloredPet.name]: 30,

    },
    {
        name: "T-2",
        [labelToColorMap.petBottles.name]: 5,
        [labelToColorMap.hdpe.name]: 50,
        [labelToColorMap.ppe.name]: 5,
        [labelToColorMap.paperClips.name]: 10,
        [labelToColorMap.carboard.name]: 20,
        [labelToColorMap.coloredPet.name]: 10,

    },
    {
        name: "T-3",
        [labelToColorMap.petBottles.name]: 10,
        [labelToColorMap.hdpe.name]: 10,
        [labelToColorMap.ppe.name]: 10,
        [labelToColorMap.paperClips.name]: 20,
        [labelToColorMap.carboard.name]: 30,
        [labelToColorMap.coloredPet.name]: 20,

    },
    {
        name: "T-4",
        [labelToColorMap.petBottles.name]: 20,
        [labelToColorMap.hdpe.name]: 10,
        [labelToColorMap.ppe.name]: 10,
        [labelToColorMap.paperClips.name]: 20,
        [labelToColorMap.carboard.name]: 20,
        [labelToColorMap.coloredPet.name]: 20,

    },
    {
        name: "T-5",
        [labelToColorMap.petBottles.name]: 20,
        [labelToColorMap.hdpe.name]: 10,
        [labelToColorMap.ppe.name]: 10,
        [labelToColorMap.paperClips.name]: 20,
        [labelToColorMap.carboard.name]: 20,
        [labelToColorMap.coloredPet.name]: 20,

    },
    {
        name: "T-6",
        [labelToColorMap.petBottles.name]: 20,
        [labelToColorMap.hdpe.name]: 10,
        [labelToColorMap.ppe.name]: 10,
        [labelToColorMap.paperClips.name]: 20,
        [labelToColorMap.carboard.name]: 20,
        [labelToColorMap.coloredPet.name]: 20,

    },
]

function AssessQuality(props) {

    const [supplier, setSupplier] = useState();

    return (
        <Box sx={{
            width: "100%",
            paddingTop: 2
        }}>
            <FormControl variant="standard" sx={{ minWidth: 280, mb: 3 }}>
                <InputLabel>Select supplier for quality assessment</InputLabel>
                <Select
                    value={supplier}
                    onChange={setSupplier}
                    label="Select supplier for quality assessment"
                >
                    <MenuItem value={0}>
                        <em>All</em>
                    </MenuItem>
                    <MenuItem value={1}>A Supplier</MenuItem>
                    <MenuItem value={2}>B Supplier</MenuItem>
                    <MenuItem value={3}>C Supplier</MenuItem>
                </Select>
            </FormControl>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{ mb: 2 }}>

                <Grid item xs={12} md="auto">
                    <MetricBox label="Quality Inspected" value="4" subText="MT" />
                </Grid>
                <Grid item xs={12} md="auto">
                    <MetricBox label="Days checked" value="7" subText="/30 days" />
                </Grid>
                <Grid item xs={12} md="auto">
                    <MetricBox label="Rank amoung suppliers" value="2" subText="/4" />
                </Grid>

            </Grid>

            <Box sx={{
                borderRadius: 2,
                border: 1,
                padding: "28px",
                paddingLeft: "48px",
                paddingRight: "48px",
                borderColor: '#E8E4E4',
                width: "100%"
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                            Overall Quality of Supplier
                        </Typography>
                        <Typography variant="h1"
                            sx={{
                                fontSize: 20,
                                mr: "8px",
                                fontWeight: 900,
                                color: "primary.main",
                                fontFamily: "Poppins",
                                mb: 5,
                                mt: 5
                            }}>
                            87
                            <Typography variant="h4" component="span" sx={{ fontWeight: 900, ml: "10px", color: "#000", fontFamily: "Poppins" }}>
                                %
                            </Typography>
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                            Day by day breakup of last 7 days
                        </Typography>
                        <VerticalBarChart height={360} data={vertialData} margin={{ right: 32 }} />
                    </Grid>
                    <Grid container item xs={12} md={8} direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                            Composition breakup
                        </Typography>
                        <StackedBarChart data={stackedData} height={520} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default AssessQuality;