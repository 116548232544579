import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const MainStyle = styled('Box')(({ theme }) => ({
    display: 'inline-flex'
}));

function TextSwitch(props) {
    const { value, handleSwitch } = props;

    const handleClick = (idx) => {
        let res = [];
        let selectedText = null;
        for (let i = 0; i < value.length; i++) {
            if (i === idx && value[i].selected === true) {
                return
            }
            let obj = {
                ...value[i],
                label: value[i].label,
                selected: (i === idx) ? true : false
            }
            res.push(obj)
            if (i === idx) selectedText = value[i].label;
        }
        let temp = {
            selectedLabel: selectedText,
            value: res
        }
        handleSwitch(temp);
    }

    return (
        <MainStyle>
            {value && value.map((item, idx) => (
                <Box sx={{
                    border: 1,
                    borderLeft: idx !== 0 ? 0 : 1,
                    borderColor: '#E8E4E4',
                    bgcolor: item.selected ? 'primary.main' : 'secondary.main',
                    color: item.selected ? 'primary.contrastText' : 'secondary.contrastText',
                    display: 'inline-block',
                    padding: '5px 10px',
                    fontWeight: 400,
                    minWidth: 128,
                    textAlign: 'center',
                    ':hover': {
                        cursor: 'pointer'
                    },
                }} onClick={() => handleClick(idx)} >
                    {item.label}
                </Box>
            ))}

        </MainStyle>
    );
}

export default TextSwitch;