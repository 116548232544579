import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { Metric } from './Metrics';

function MetricBox(props) {
    return (
        <Box sx={{
            borderRadius: 2,
            border: 1,
            padding: "18px",
            borderColor: '#E8E4E4',
            width: "360px"
        }}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
                {props.label}
            </Typography>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Metric value={props.value} subText={props.subText} />
        </Box>
    );
}

export default MetricBox;