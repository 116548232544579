import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, LabelList, ResponsiveContainer } from 'recharts';

function VerticalBarChart(props) {

    return (
        <ResponsiveContainer width="100%" height={props.height}>
            <BarChart
                {...props}
                layout="vertical"
            >
                <XAxis hide type="number" domain={[0, 100]} />
                <YAxis type="category" dataKey="name" tickLine={false} axisLine={false} />
                <Bar dataKey="value" fill="#8884d8" barSize={40}>
                    <LabelList dataKey="label" position="right" />
                    {props.data.map((entry, index) => (
                        <Cell fill={entry.color} key={`cell-${index}`} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer >
    );
}

export default VerticalBarChart;